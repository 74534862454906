import React from 'react'
import Navbar from '../inc/Navbar'
import "./Home.css";
import Logoimg from "../images/lgog.png";
import Slider from '../inc/Slider';
import Tranding from "../pages/Tranding";


function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <div className='album py-0'>
      <div className='container'>
        <h6>Magic Berhampore</h6>
        </div>
        </div>
      <Slider/>

      <div className='magicberhampore'>
        <div className='album py-1'>
          <div className='container '>

            <marquee><h6 style={{ color: "white" }} className=' mt-3' >Welcome to Magic Berhampore, A Multimedia Production House & Customized Gift Shop</h6></marquee>
          </div>
        </div>
      </div>
      <br/>
      <div className="album py-1">
<div className="container">
  <h1 style={{color:"grey"}}>TRENDING <b>PRODUCTS  </b>- MAGIC</h1>
  </div>
  </div>
      <Tranding/>
      {/* <center>

        <img src={Logoimg} className="img-fluid" alt="magic" />
      </center> */}

      
     

      {/* <center>
        <div className='album py-3'>
          <div className='container'>

            <div className='blink update'>
              <p style={{ color: "maroon" }} >Website Development Under Process, Update Soon...</p>
            </div>
          </div>
        </div>
      </center> */}


    </div>
  )
}

export default Home