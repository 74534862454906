
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyAkUtgvrF10wMVmudC89po9j1rEX0s1HOk",
    authDomain: "magic-wb.firebaseapp.com",
    projectId: "magic-wb",
    storageBucket: "magic-wb.appspot.com",
    messagingSenderId: "494085627297",
    appId: "1:494085627297:web:54518b9f24163a3fa13fe0",
    measurementId: "G-042M26P9C9"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;