import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {Link} from "react-router-dom";

import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Trandingpage from './components/pages/Trandingpage';
import Contact from './components/pages/Contact';

function App() {
  return (
    <Router>
    <div>
      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/trending-products" element={<Trandingpage/>}/>
      <Route axact path="/Contact" element={<Contact/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
