import React from 'react'
import ScrollToTop from "react-scroll-to-top";
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
import Tranding from "../pages/Tranding";
function Trandingpage() {
    return (
        <div>
            <Navbar />

            <br />
            <ScrollToTop smooth top="100" color="grey" />

            <br />
            <br />
       



            <div className='giftberhampore'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" href="#">HOME</Link></li>
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "black" }} >BACK</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">TRENDING PRODUCTS</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
            <Tranding/>
        </div>
    )
}

export default Trandingpage