import React from 'react'
import Logo from "../images/lgog.png";
import {Link} from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <div>
        <div className='fixed-top'>
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
  <div className="container-fluid">
  <Link to="/">
    <img src={Logo} width="140px" height="60px" alt='logo'/>
  </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" href="#">HOME</Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link" href="#">ABOUT US</Link>
        </li>
        <li className="nav-item">
          <Link to="/trending-products" className="nav-link" href="#">TRENDING PRODUCTS</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            PRODUCTS
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="#">GIFT</a></li>
            <li><a className="dropdown-item" href="#">MEMENTO</a></li>

            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="#">VIDEO PRODUCTION</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/Contact" className="nav-link" href="#">CONTACT US</Link>
        </li>
      </ul>
    
        <a href='tel: +91 7501566730'>
        <div className="btn-grad15" type="submit">Book Appointment</div>
        </a>
      
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar